"use strict";

// Namespacing
window.site = window.site || {}; // Side panel - opened / close

site.AjaxCart = function AjaxCart($) {
  'use strict';
  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false,
      $addForm = $('[data-ajax="addToCart"]'),
      $removeForm = $('[data-ajax="removeFromCart"]'),
      $updateQtyForm = $('[data-ajax="updateQty"]'),
      $cartPreview = $('#cart-preview'),
      $cartQty = $('[data-cart-qty]'),
      $passesOptions = $('#checkout-cart-form'),
      isUpdating = false;
  /**
   * Initializes the class.
   * @public
   */

  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true; // Initiate AjaxCart
    // Add To Cart Action

    addToCart(); // Remove To Cart Action

    removeFromCart(); // Update Qty Action

    updateQty();
    handleShippingRate(); // Including Rentals Tickets CrossSale

    activateDataTriggerAlert(); // Custom Alert

    $('body').on('click', '#cart-preview .button.right, #cart-preview .button.left, #cart-preview .remove', function (e) {
      e.preventDefault();
      var $btn = $(this);

      if ($btn.hasClass('withNotif')) {
        var alertTxt = 'Les informations non enregistrées seront perdues si vous modifiez votre panier actuel.';

        if ($('html').attr('lang') == 'en') {
          alertTxt = 'Non saved informations will be lost if you modify your current cart.';
        }

        swal({
          title: "Attention",
          text: alertTxt,
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Modifier",
          cancelButtonText: "Annuler",
          closeOnConfirm: true,
          html: false
        }, function () {
          if ($btn.hasClass('right')) {
            var _input = $btn.closest('.custom-quantity').find('input[type=number]');

            _input.val(+_input.val() + 1);
          } else if ($btn.hasClass('left')) {
            var _input = $btn.closest('.custom-quantity').find('input[type=number]'),
                new_val = +_input.val() - 1;

            if (new_val < 1) new_val = 1;

            _input.val(new_val);
          }

          var $cartRefresh = $btn.parents('form');

          if ($cartRefresh.length) {
            $btn.prop('disabled', true);
            $cartRefresh.submit();
          }
        });
      } else {
        if ($btn.hasClass('right')) {
          var _input = $btn.closest('.custom-quantity').find('input[type=number]');

          _input.val(+_input.val() + 1);
        } else if ($btn.hasClass('left')) {
          var _input = $btn.closest('.custom-quantity').find('input[type=number]'),
              new_val = +_input.val() - 1;

          if (new_val < 1) new_val = 1;

          _input.val(new_val);
        }

        var $cartRefresh = $btn.parents('form');

        if ($cartRefresh.length) {
          $btn.prop('disabled', true);
          $cartRefresh.submit();
        }
      }
    });
    return true;
  };

  var addToCart = function addToCart() {
    $addForm.on("submit", function (event) {
      event.preventDefault();
      var $form = $(this);
      sendFormByAjax($form);
    });
  };

  var removeFromCart = function removeFromCart() {
    $cartPreview.on("submit", '[data-ajax="removeFromCart"]', function (event) {
      event.preventDefault();
      var $form = $(this);
      sendFormByAjax($form);
    });
  };

  var updateQty = function updateQty() {
    $cartPreview.on("submit", '[data-ajax="updateQty"]', function (event) {
      event.preventDefault();
      var $form = $(this);
      sendFormByAjax($form);
    });
  };

  var sendFormByAjax = function sendFormByAjax($form) {
    // Disable multiple form send
    $('[type="submit"]', $form).prop('disabled', true);
    $form.append($('<input/>').attr('type', 'hidden').attr('name', 'site_csrf').val(site.App.config('csrf')));
    $.ajax({
      type: 'post',
      url: $('[name="action"]', $form).val(),
      dataType: 'json',
      data: $form.serializeArray(),
      success: function success(response) {
        if (!response.success) {
          alert(response.error); // Re-Enable send button

          $('[type="submit"]', $form).prop('disabled', false);
          return false;
        }

        console.log(response); // Refresh cart small components

        var updatedCart = response.cart;
        $cartQty.text(updatedCart.totalQty); // Refresh Cart preview
        // Load new state of cart-preview.twig
        // if (updatedCart.totalQty == 0) {
        //   window.location.replace("/");
        // } else {
        //   $cartPreview.load("/_pages/shop/_partials/cart-ajax.twig");
        //   $passesOptions.load("/_pages/shop/_partials/passes-options.twig");
        // }
        // Open Cart Preview with loading
        // window.site.SidePanelCart.openPanel(true);
        // Re-Enable send button

        $('[type="submit"]', $form).prop('disabled', false);
        location.reload();
        return true;
      }
    });
  };

  var handleShippingRate = function handleShippingRate() {
    $('body').on('click change', '[name="shippingMethodHandle"]', function (event) {
      /*if (isUpdating) {return false};
      isUpdating = true;*/
      var selected = $('[name="shippingMethodHandle"]:checked').eq(0).val();

      if (selected === 'freeShipping') {
        var $form = $('#ajaxFreeShipping');
      }

      if (selected === 'flatShipping') {
        var $form = $('#ajaxFlatShipping');
      }

      if (selected === 'defaultShipping') {
        var $form = $('#ajaxDefaultShipping');
      }

      $('[type="submit"]', $form).prop('disabled', true);
      /*$form.append(
          $('<input/>')
            .attr('type','hidden')
            .attr('name','site_csrf')
            .val(site.App.config('csrf'))
      );*/

      var urlParams = new URLSearchParams(window.location.search);
      var persistLang = '';

      if ($('html').attr('lang') == 'en') {
        persistLang = '/en';
      }

      $.ajax({
        type: 'post',
        url: $('[name="action"]', $form).val(),
        data: $form.serializeArray(),
        dataType: 'json',
        success: function success(response) {
          console.log('ajaxed');

          if (!response.success) {
            alert(response.error); // Re-Enable send button

            $('[type="submit"]', $form).prop('disabled', false);
            return false;
          }

          $cartPreview.find('.refresh-zone').load("/cart-ajax.twig", {
            "refererPage": window.location.href
          });
          $('[type="submit"]', $form).prop('disabled', false);
          isUpdating = false;
          return true;
        }
      });
    });
  };

  var activateDataTriggerAlert = function activateDataTriggerAlert() {
    $('body').on('click', '[data-trigger-alert]', function (event) {
      event.preventDefault();
      var $btn = $(this);
      var alertTxt = ["Attention", "Vous n'avez présentement aucun billet avec votre panier de location(s). Voulez-vous ajouter les(s) billet(s) automatiquement?", "Ajouter le(s) billet(s)", "Continuer sans billet"];

      if (site.App.config('locale') === 'en') {
        var alertTxt = ["Warning", "You do not have any tickets associated with your rental(s). Do you wish to add the ticket(s) automatically ?", "Add ticket(s)", "Continue without tickets"];
      }

      if ($btn.attr('data-trigger-alert')) {
        swal({
          title: alertTxt[0],
          text: alertTxt[1],
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#7ac143",
          cancelButtonColor: "#DD6B55",
          confirmButtonText: alertTxt[2],
          cancelButtonText: alertTxt[3],
          closeOnConfirm: true,
          html: false
        }, function (accepts) {
          if (accepts) {
            console.log("Add Ticket!!!", accepts);

            if ($('.rentalsCrossSaleTickets').length) {
              // activateRentalsCrossSaleTickets
              var ticketQty = 0;
              $.each($('.rentalsCrossSaleTickets'), function () {
                ticketQty += parseInt($(this).find('input[name="qty"]').val());
              });
              $.each($('.rentalsCrossSaleTickets'), function (index, val) {
                var $crossSaleForm = $(this);
                var remainingTickets = getRemainingTickets($crossSaleForm, parseInt($(this).find('[name="isNight"]').val()), parseInt($(this).find('[name="isDay"]').val()), parseInt($(this).find('[name="isMorning"]').val()), ticketQty);

                if (remainingTickets['status']) {
                  triggerNoTicketSwal(remainingTickets['type'], $btn.attr('href'));
                  return false;
                }

                setTimeout(function () {
                  $.ajax({
                    type: 'post',
                    url: $('[name="action"]', $crossSaleForm).val(),
                    dataType: 'json',
                    data: $crossSaleForm.serializeArray(),
                    error: function error(response) {
                      console.log('error');
                      console.log(response);
                    },
                    success: function success(response) {
                      console.log('success');
                      console.log(response);

                      if (!response.success) {
                        alert(response.error);
                      }

                      setTimeout(function () {
                        // Skip CrossSlae and directly send user to checkout
                        window.location.href = $btn.attr('href');
                      }, 1000);
                    }
                  });
                }, 375);
              });
            } else {
              triggerNoTicketSwal('no-tickets', $btn.attr('href'));
            }
          } else {
            // Skip CrossSlae and directly send user to checkout
            window.location.href = $btn.attr('href');
          }
        });
      }
    });
  };

  var triggerNoTicketSwal = function triggerNoTicketSwal(errorType, redirectTarget) {
    var alertTxt = ["Attention", "Il n'y a pas de billet de disponible ou le système n'a pas retrouvé de billet pour vos options d'équipements. Voulez-vous ajouter le(s) billet(s) manuellement?", "Ajouter le(s) billet(s)", "Continuer sans billet", "/billets?date=", 'Il n\'y a pas assez de billets disponible pour cette date pour combler votre demande', 'La billetterie est / a été fermée pour cette date', 'Changer la date choisie', '/location-calendrier'];

    if (site.App.config('locale') === 'en') {
      var alertTxt = ["Warning", "There are no tickets available or the system did not find a matching ticket for your rental options. Do you wish to add the ticket(s) manually ?", "Add ticket(s)", "Continue without tickets", "/en/tickets?date=", 'There are not enough tickets for this date to fulfill your request', 'The ticketing is or has been closed for this date.', 'Change chosen date', '/en/rental-calendar'];
    }

    var swalText = alertTxt[1];

    if (errorType == 'sold-out') {
      swalText = alertTxt[5];
    } else if (errorType == 'closed') {
      swalText = alertTxt[6];
    }

    setTimeout(function () {
      swal({
        title: alertTxt[0],
        text: swalText,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: errorType == 'no-tickets' ? alertTxt[2] : alertTxt[7],
        cancelButtonText: alertTxt[3],
        closeOnConfirm: true,
        html: false
      }, function (accepts) {
        if (accepts) {
          if (errorType == 'no-tickets') {
            var ticketDate = $('.rentalsCrossSaleTickets').find('input[name="fields[orderTicketsDay]"]').val();
            window.location.href = alertTxt[4] + document.querySelector('[action-order-day]').dataset.orderDay;
          } else {
            window.location.href = alertTxt[8];
          }
        } else {
          window.location.href = redirectTarget;
        }

        return false;
      });
    }, 375);
  };

  var getRemainingTickets = function getRemainingTickets(form, isNight, isDay, isMorning, ticketQty) {
    var addingTicketsError = false;
    var ticketErrorType = 'closed';
    var ticketDate = $('.rentalsCrossSaleTickets').find('input[name="fields[orderTicketsDay]"]').val();
    var cartTicketsDay = $('[data-qty-tickets-day]');
    var cartTicketsNight = $('[data-qty-tickets-night]');
    var cartTicketsMorning = $('[data-qty-tickets-morning]');
    var totalCartTicketsDay = 0;
    var totalCartTicketsNight = 0;
    var totalCartMorning = 0;
    cartTicketsDay.each(function () {
      totalCartTicketsDay += parseFloat($(this).text());
    });
    cartTicketsNight.each(function () {
      totalCartTicketsNight += parseFloat($(this).text());
    });
    cartTicketsMorning.each(function () {
      totalCartMorning += parseFloat($(this).text());
    });
    $.ajax({
      type: 'get',
      url: '/site/getRemainingTickets?date=' + ticketDate,
      dataType: 'json',
      async: false,
      success: function success(res) {
        if (res['isClosed'] == true) {
          addingTicketsError = true;
        } else {
          if (isDay) {
            var remainingTicket = res['limitDay'] - ticketQty - totalCartTicketsDay;
          } else if (isNight) {
            var remainingTicket = res['limitNight'] - ticketQty - totalCartTicketsNight;
          } else if (isMorning) {
            var remainingTicket = res['limitMorning'] - ticketQty - totalCartMorning;
          }

          if (remainingTicket < 0) {
            addingTicketsError = true;
            ticketErrorType = 'sold-out';
          }
        }
      }
    });
    return {
      'status': addingTicketsError,
      'type': ticketErrorType
    };
  };

  return {
    init: init
  };
}.bind(site, jQuery)();
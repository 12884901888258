"use strict";

// Namespacing
window.site = window.site || {}; // Component

site.LocationSkiGo = function LocationSkiGo($) {
  'use strict';
  /**
   * JQuery variables
   * @private
   */

  var $ageValueSki = $('[action-age-value-s]');
  var $hourValueSki = $('[action-go-value-s]');
  var $ageValuePlanche = $('[action-age-value-p]');
  var $hourValuePlanche = $('[action-go-value-p]');
  var goArraySki = {
    'LSE': 'LSKIGOE3',
    'LSJ': 'LSKIGOJ3',
    'LSA': 'LSKIGOA3',
    'LSA-2': 'LSKIGOA3',
    'LSSE': 'LSKIGOA3'
  };
  var goArrayPlanche = {
    'LPE': 'LSNOGOE3',
    'LPJ': 'LSNOGOJ3',
    'LPA': 'LSNOGOA3',
    'LPA-2': 'LSNOGOA3',
    'LPSE': 'LSNOGOA3'
  };
  var $checkExtraFields = $('.js-extra-field');
  var $extraFields = $('.js-show-extra');
  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;
  /**
   * Initializes the class.
   * @public
   */

  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    $ageValueSki.on('change', function (e) {
      $hourValueSki.val(goArraySki[$(this).val()]);
    });
    $ageValuePlanche.on('change', function (e) {
      $hourValuePlanche.val(goArrayPlanche[$(this).val()]);
    });
    $checkExtraFields.on('change', function (e) {
      if ($(this).val() == 1) {
        $(this).closest('.addressBox').find($extraFields).addClass('is-visible');
      } else {
        $(this).closest('.addressBox').find($extraFields).removeClass('is-visible');
        $(this).closest('.addressBox').find($extraFields).each(function () {
          $(this).val($(this).find('select').prop('selectedIndex', -1));
        });
      }
    });
    inited = true;
    return true;
  }; // Expose public methods & properties


  return {
    init: init
  };
}.bind(site, jQuery)();
"use strict";

// Namespacing
window.site = window.site || {}; // Side panel - opened / close

site.LocationsAjaxCart = function LocationsAjaxCart($) {
  'use strict';
  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false,
      $cartPreview = $('#cart-preview'),
      $cartQty = $('[data-cart-qty]'),
      $passesOptions = $('#checkout-cart-form'); // isUpdating = false;

  /**
   * Initializes the class.
   * @public
   */

  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true; // Initiate AjaxCart
    // Add To Cart Action

    addToCart(); // Remove To Cart Action

    removeFromCart(); // Update Qty Action

    updateQty();
    return true;
  };

  var addToCart = function addToCart() {
    console.log("Prep AddToCart");
    $('body').on("submit", '[data-ajax="ticketAddToCart"]', function (event) {
      event.preventDefault();
      var $form = $(this);
      $('.sidepanel-overlay').addClass('opened is-loading'); // Clear Cart if needed

      if ($('[data-clear-cart]').length) {
        var $clear = $('[data-clear-cart]');
        $.ajax({
          type: 'post',
          url: $('[name="action"]', $clear).val(),
          data: $clear.serializeArray(),
          success: function success(res) {
            sendFormByAjax($form);
            $clear.remove();
          }
        });
      } else {
        sendFormByAjax($form);
      }
    });
  };

  var removeFromCart = function removeFromCart() {
    $('body').on("submit", '[data-ajax="ticketRemoveFromCart"]', function (event) {
      event.preventDefault();
      var $form = $(this);
      sendFormByAjax($form);
    });
  };

  var updateQty = function updateQty() {
    $('body').on("submit", '[data-ajax="updateQtyTicket"]', function (event) {
      event.preventDefault();
      var $form = $(this);
      sendFormByAjax($form);
    });
  };

  var sendFormByAjax = function sendFormByAjax($form) {
    // Disable multiple form send
    $('[type="submit"]', $form).prop('disabled', true);
    var persistLang = '';

    if ($('html').attr('lang') == 'en') {
      persistLang = '/en';
    }

    console.log(persistLang + $('[name="action"]', $form).val());
    $.ajax({
      type: 'post',
      url: $('[name="action"]', $form).val(),
      dataType: 'json',
      data: $form.serializeArray(),
      success: function success(response) {
        if (!response.success) {
          alert(response.error); // Re-Enable send button

          $('[type="submit"]', $form).prop('disabled', false);
          return false;
        }

        console.log(response); // Refresh cart small components

        var updatedCart = response.cart;
        $cartQty.text(updatedCart.totalQty); // Refresh Cart preview
        // Load new state of cart-preview.twig

        if (updatedCart.totalQty == 0) {
          window.location.replace("/");
        } else {
          $cartPreview.load("cart-ajax.twig", {
            "refererPage": window.location.href
          });
          $passesOptions.load(persistLang + "passes-options.twig", {
            "refererPage": window.location.href
          });
        } // Open Cart Preview with loading


        window.site.SidePanelCart.openPanel(true); // Re-Enable send button

        $('[type="submit"]', $form).prop('disabled', false); // location.reload();

        return true;
      }
    });
  };

  return {
    init: init
  };
}.bind(site, jQuery)();
"use strict";

window.site = window.site || {};
/**
 * Initilize sticky side navigation
 * @class StickyContent
 * @static
 */

site.StickyContent = function StickyContent($) {
  'use strict';
  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false,
      navigation = document.querySelector('#sticky-content'),
      dropdown = document.querySelector('#dropdown'),
      dropdownTrigger = document.querySelector('#dropdown-trigger');
  /**
   * Initializes the class.
   * @public
   */

  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true; // Set stickyness
    // initSticky();
    //Initiate mobile

    handleNavigationTrigger();
    return true;
  };
  /**
   * Set sticky on side navigation
   * @public
   */


  var initSticky = function initSticky() {
    console.log($('.sticky-content').outerHeight(), $('.main-form').outerHeight());

    if (navigation) {
      var navigationOffset = 410;
      window.addEventListener('scroll', function (event) {
        if (window.scrollY > navigationOffset && $('#cart-preview').css('margin-left') === '25px' && $('.sticky-content').outerHeight() !== $('.main-form').outerHeight()) {
          var top = window.scrollY - navigationOffset > 0 ? window.scrollY - navigationOffset : 0;
          navigation.classList.add('fixed');
          navigation.style.top = top + 'px';
        } else {
          navigation.classList.remove('fixed');
          navigation.style.top = 0;
        }
      });
    }

    return false;
  };
  /**
   * Toggle navigation open/close
   * @public
   */


  var toggleNavigation = function toggleNavigation() {
    if (dropdown.length) {
      dropdown.classList.toggle('show');
      return true;
    }

    return false;
  };
  /**
   * Handle trigger navigation open/close
   * @public
   */


  var handleNavigationTrigger = function handleNavigationTrigger() {
    if (dropdownTrigger) {
      dropdownTrigger.addEventListener('click', function (event) {
        toggleNavigation();
      });
      return true;
    }

    return false;
  }; // Expose public methods & properties


  return {
    init: init
  };
}(jQuery);
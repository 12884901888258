"use strict";

// Namespacing
window.site = window.site || {}; // Component

site.TicketsDatePicker = function TicketsDatePicker($) {
  'use strict';
  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;
  var $ticketsDatePicker = $('#ticketsDatePickerNew');
  var lastSelection = null;
  var topOfNav = 9999;
  var translatedContent = null;
  var openingDate = $ticketsDatePicker.attr('data-date-opening');
  var closingDate = $ticketsDatePicker.attr('data-date-closing');
  var closingArray = $ticketsDatePicker.attr('data-date-closing-array');
  var minDate = $ticketsDatePicker.attr('data-date-min');
  /**
   * Initializes the class.
   * @public
   */

  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;
    translatedContent = getTranslatedContent();

    if ($ticketsDatePicker.length) {
      console.log('has picker');
      var formattedClosedArray = [];

      if (openingDate) {
        openingDate = $ticketsDatePicker.attr('data-date-opening').split(",");
      }

      if (closingDate) {
        closingDate = $ticketsDatePicker.attr('data-date-closing').split(",");
      }

      if (closingArray) {
        closingArray = $ticketsDatePicker.attr('data-date-closing-array').split("|");
        closingArray.forEach(function (element) {
          var splitElement = element.split(',');
          formattedClosedArray.push([parseInt(splitElement[0]), parseInt(splitElement[1]), parseInt(splitElement[2])]);
        });
      }
      /*var disabledDates = [
        ...formattedClosedArray,
        [2021,11,2],
        { from: [(parseInt(closingDate[0])-1),0,1], to: openingDate }
      ];*/


      var disabledDates = [];
      console.log('-----DISABLED DATES------');
      console.log(disabledDates);
      console.log(formattedClosedArray);
      /*// For rentals, we don't allow Weekends to be selected
      if ($('#location-component').length) {
        disabledDates = [
           { from: [(parseInt(closingDate[0])-1),0,1], to: openingDate }
        ];
      }*/

      var tempDisabledDates = [true, 7, 8, [2020, 9, 12]];
      var tempDisableDates2 = [true, [2020, 9, 3], [2020, 9, 4], [2020, 9, 10], [2020, 9, 12], [2020, 8, 26], [2020, 9, 17], [2020, 9, 18], [2020, 9, 24], [2020, 9, 25], [2020, 9, 31], [2020, 10, 1]];
      /*$ticketsDatePicker.pickadate({
        // Strings and translations
        monthsFull: translatedContent.monthsFull,
        monthsShort: translatedContent.monthsShort,
        weekdaysFull: translatedContent.weekdaysFull,
        weekdaysShort: translatedContent.weekdaysShort,
        showMonthsShort: undefined,
        showWeekdaysFull: undefined,
         // Buttons
        today: '',
        clear: translatedContent.clear,
        close: translatedContent.close,
         // Accessibility labels
        labelMonthNext: translatedContent.labelMonthNext,
        labelMonthPrev: translatedContent.labelMonthPrev,
        labelMonthSelect: translatedContent.labelMonthSelect,
        labelYearSelect: translatedContent.labelYearSelect,
         // Formats
        format: 'dddd, mmmm d, yyyy',
        formatSubmit: 'YYYY-MM-DD',
        hiddenPrefix: undefined,
        hiddenSuffix: '',
        hiddenName: true,
         // Editable input
        editable: undefined,
         // Dropdown selectors
        selectYears: undefined,
        selectMonths: undefined,
         // First day of the week
        firstDay: undefined,
         // Date limits
        min: parseInt(minDate),
        // min: Date.now(),
        // max: new Date(parseInt(closingDate[0]), parseInt(closingDate[1]), parseInt(closingDate[2])),
        max: undefined,
         // Disable dates
        disable: disabledDates,
         // Root picker container
        container: undefined,
         // Hidden input container
        containerHidden: undefined,
         // Close on a user action
        closeOnSelect: true,
        closeOnClear: true,
         // Events
        onStart: undefined,
        onRender: undefined,
        onOpen: undefined,
        onClose: undefined,
        onSet: function(context) {
           if (context) {
            if (!context.select) {
              return false;
            }
          }
           if (!this.get('value')) {
            return false;
          }
           // if ( lastSelection === this.get('value')) {
          //   return false;
          // }
           // lastSelection = this.get('value');
           // console.log(lastSelection);
           var $ajaxZone = $('#ajax-tickets');
           var $loader = '<div class="loader-container" style="height: ' + $ajaxZone.outerHeight() + 'px;"><div class="loader"><svg class="circular" viewBox="25 25 50 50"><circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/></svg></div></div>';
           $ajaxZone.empty().append($loader);
           $('.tickets-cta-wrapper').fadeOut(555);
           console.log('formatDate on context', context.select);
          console.log(formatDate(context.select));
           var hourlyQty = [];
           $.ajax({
            type: 'get',
            url: '/site/getHourAvailability?date=' + formatDate(context.select),
            dataType: 'json',
            async: false,
            success: function(res) {
              hourlyQty = res
            }
          });
           console.log(hourlyQty)
           var data = {
            'userDate': formatDate(context.select)
          };
           if ($('[name="fields[orderTicketsDay]"]').length) {
            $('[name="fields[orderTicketsDay]"]').val(formatDate(context.select));
            $('[name="locationAge"]').val('');
            $('[name="locationDuration"]').val('');
          }
           $('.pickup-hours option').not(':first-child').each(function(e) {
            var currentOption = $(this).val();
            var splitOptions = currentOption.split(' ');
            var currentHour = !splitOptions[1] ? splitOptions[0] : splitOptions[1];
            var currentCount = hourlyQty[formatDate(context.select) + ' ' + currentHour];
            var quarterLimit = $('[name="quarterLimit"]').val();
             if(currentCount >= quarterLimit) {
              $(this).css('display', 'none');
              $(this).prop('disabled', true)
            } else {
              $(this).css('display', 'block');
              $(this).prop('disabled', false)
            }
             $(this).val(formatDate(context.select) + ' ' + currentHour)
          })
           console.log('formatDate on context', data);
           // console.log("--//--");
          // console.log(this.get('value'));
          // console.log(context.select);
          // console.log(formatDate(context.select));
          // console.log(moment(this.get('value'), "dddd, MMMM DD, YYYY").lang("fr-ca").format('YYYY-MM-DD'));
          // console.log(moment().lang("fr-ca"));
          // console.log("--//--");
           // console.log('[DatePicker] data', data);
          // console.log('[DatePicker] data', formatDate(this.get('value')));
           // console.log("icicicicicici");
          console.log($ticketsDatePicker.attr('data-page'));
           if ($ticketsDatePicker.attr('data-page') === 'locations') {
            console.log('cicked');
             var persistLang = '/location-calendrier';
             if ($('html').attr('lang') == 'en') {
              persistLang = '/en/rentals-calendar';
            }
             $.ajax({
              url: persistLang,
              type: 'GET',
              data: data,
              dataType : 'json',
              encode : true
            })
              .done(function(data) {
                // console.log("[DatePicker] success", data);
              })
              .fail(function(data) {
                // console.log("[DatePicker] error", data.message);
              })
              .always(function(res) {
                // console.log("[DatePicker] complete", data.responseText);
                var $updateSkusQty = $(res.responseText).find('#data-skus-qty').html();
                $('#data-skus-qty').empty().html($updateSkusQty);
                 // Replace inner HTML with alert
                // Rentals available only during weekdays
                // if ( (new Date(data.userDate).getDay() + 1) < 6 ) {
                  $('#rentals-select').removeClass('is-hidden');
                  $('#rentals-alert').hide();
                // } else {
                //   $('#rentals-select').addClass('is-hidden');
                //   $('#rentals-alert').show();
                // }
              });
           } else {
            var urlParams = new URLSearchParams(window.location.search)
            var persistLang = '/billets?date=' + urlParams.get('date');
             if ($('html').attr('lang') == 'en') {
              persistLang = '/en/billets?date=' + urlParams.get('date');
            }
             $.ajax({
              url: persistLang,
              type: 'GET',
              data: data,
              dataType : 'json',
              encode : true
            })
              .done(function(data) {
                // console.log("[DatePicker] success", data);
              })
              .fail(function(data) {
                // console.log("[DatePicker] error", data.message);
              })
              .always(function(data) {
                // console.log("[DatePicker] complete", data.responseText);
                var $updatedTicketsList = $(data.responseText).find('#tickets-list');
                $updatedTicketsList.find('.ticket').addClass('loading');
                console.log($updatedTicketsList);
                 setTimeout(function () {
                  $ajaxZone.empty().append($updatedTicketsList);
                   $.each( $ajaxZone.find('[data-format-price]'), function(index, val) {
                     var delimiter = ',';
                     if (window.site.App.config('locale') === 'en') {
                      delimiter = '.';
                    }
                     var $price = $(this);
                    var actualValue = $price.text();
                    var parts = actualValue.split(delimiter);
                     if (window.site.App.config('locale') === 'en') {
                      $price.html('$' + parts[0] + delimiter + '<sup>' + parts[1] + '</sup>');
                    }else {
                      $price.html(parts[0] + delimiter + '<sup>' + parts[1] + '$</sup>');
                    }
                   });
                   $.each($ajaxZone.find('.ticket'), function(index, val) {
                    var $ticket = $(this);
                    setTimeout(function(){
                      $ticket.removeClass('loading');
                    }, (193 * index));
                  });
                   topOfNav = $('#tickets-list').offset().top;
                   refreshTicketsCTA();
                }, 1111);
              });
           }
        },
         onStop: undefined,
         // Classes
        klass: {
           // The element states
          input: 'picker__input',
          active: 'picker__input--active',
           // The root picker and states *
          picker: 'picker',
          opened: 'picker--opened',
          focused: 'picker--focused',
           // The picker holder
          holder: 'picker__holder',
           // The picker frame, wrapper, and box
          frame: 'picker__frame',
          wrap: 'picker__wrap',
          box: 'picker__box',
           // The picker header
          header: 'picker__header',
           // Month navigation
          navPrev: 'picker__nav--prev',
          navNext: 'picker__nav--next',
          navDisabled: 'picker__nav--disabled',
           // Month & year labels
          month: 'picker__month',
          year: 'picker__year',
           // Month & year dropdowns
          selectMonth: 'picker__select--month',
          selectYear: 'picker__select--year',
           // Table of dates
          table: 'picker__table',
           // Weekday labels
          weekdays: 'picker__weekday',
           // Day states
          day: 'picker__day',
          disabled: 'picker__day--disabled',
          selected: 'picker__day--selected',
          highlighted: 'picker__day--highlighted',
          now: 'picker__day--today',
          infocus: 'picker__day--infocus',
          outfocus: 'picker__day--outfocus',
           // The picker footer
          footer: 'picker__footer',
           // Today, clear, & close buttons
          buttonClear: 'picker__button--clear',
          buttonClose: 'picker__button--close',
          buttonToday: 'picker__button--today'
        }
      });*/
    }

    var $picker = $ticketsDatePicker.pickadate('picker');
    $('.icon-calendar-wrapper, .datepicker-delegate').on('click', function (event) {
      event.preventDefault();
      event.stopPropagation();
      var isInTicket = $(this).hasClass('for-tickets-delegate');
      var isInRentals = $(this).hasClass('for-rentals-delegate');

      if (parseInt($('[data-cart-qty]').text()) >= 1) {
        swal({
          title: translatedContent.swalTitle,
          text: translatedContent.swalMsg,
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Modifier",
          cancelButtonText: "Annuler",
          closeOnConfirm: true,
          html: false
        }, function () {
          if (isInTicket) {
            // console.log('hi');
            window.location.replace("/");
          } else if (isInRentals) {
            if ($('html').attr('lang') == 'en') {
              window.location.replace("/en/rental-calendar");
            } else {
              window.location.replace("/location-calendrier");
            }
          } else {
            $('#ticketsDatePicker').pickadate('picker').open();
          }

          return false;
        });
      } else {
        if (isInTicket) {
          // console.log('hi')
          if ($('html').attr('lang') == 'en') {
            window.location.replace("/en");
          } else {
            window.location.replace("/");
          }
        } else if (isInRentals) {
          if ($('html').attr('lang') == 'en') {
            window.location.replace("/en/rental-calendar");
          } else {
            window.location.replace("/location-calendrier");
          }
        }
      }

      if (!isInTicket && !isInRentals) {
        $picker.open();
      }
    });

    if ($ticketsDatePicker.attr('data-preselect-date')) {
      var dateArr = $ticketsDatePicker.attr('data-preselect-date').split('-');
      console.log("dateArr !!!", dateArr); // Using arrays formatted as [YEAR, MONTH, DATE].
      // $picker.set('select', new Date(parseInt(dateArr[0]), parseInt(dateArr[1]) - 1, parseInt(dateArr[2])));

      $('#rentals-select').removeClass('is-hidden');
    } else {
      $('#rentals-select').addClass('is-hidden'); // console.log("dateArr", 'Default!!!');
      // var selectDate = new Date();
      // selectDate = selectDate.addDays(2);
      // $picker.set('select', selectDate);
    }

    refreshTicketsCTA();
    activateFollowDatepickerBar();
    return true;
  };

  var getTranslatedContent = function getTranslatedContent() {
    if (site.App.config('locale') === 'en') {
      return {
        monthsFull: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        weekdaysFull: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        showMonthsShort: undefined,
        showWeekdaysFull: undefined,
        // Buttons
        today: 'Today',
        clear: 'Clear',
        close: 'Close',
        // Accessibility labels
        labelMonthNext: 'Next month',
        labelMonthPrev: 'Previous month',
        labelMonthSelect: 'Select a month',
        labelYearSelect: 'Select a year',
        'swalTitle': 'Warning',
        'swalMsg': 'Changing the date, will reset the cart. Please finalize your purchase for this date, before choosing another one.'
      };
    } else {
      return {
        // Strings and translations
        monthsFull: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
        monthsShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Jun', 'Jul', 'Aoû', 'Sep', 'Oct', 'Nov', 'Déc'],
        weekdaysFull: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
        weekdaysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
        showMonthsShort: undefined,
        showWeekdaysFull: undefined,
        // Buttons
        today: 'Aujourd\'hui',
        clear: 'Annuler',
        close: 'Fermer',
        // Accessibility labels
        labelMonthNext: 'Mois Suivant',
        labelMonthPrev: 'Mois précédent',
        labelMonthSelect: 'Choisir le mois',
        labelYearSelect: 'Choisir l\'année',
        'swalTitle': 'Attention',
        'swalMsg': 'Si vous changez la date, le panier sera réinitialisé. Veuillez finaliser votre achat pour cette journée, avant d\'en choisir une autre.'
      };
    }
  };

  var formatDate = function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  };

  var refreshTicketsCTA = function refreshTicketsCTA() {
    if (parseInt($('[data-cart-qty]').text()) >= 1) {
      $('.tickets-cta-wrapper').fadeIn(555);
    } else {
      $('.tickets-cta-wrapper').fadeOut();
    }
  };

  var activateFollowDatepickerBar = function activateFollowDatepickerBar() {
    // Not a ton of code, but hard to
    var nav = document.querySelector('#datepicker-follow');

    if (!nav) {
      return false;
    }

    function fixNav() {
      // console.log(window.scrollY, topOfNav);
      if (window.scrollY >= topOfNav) {
        // document.body.style.paddingTop = nav.offsetHeight + 'px';
        nav.classList.add('is-active');
      } else {
        nav.classList.remove('is-active'); // document.body.style.paddingTop = 0;
      }
    }

    window.addEventListener('scroll', fixNav);
  };

  Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  }; // Expose public methods & properties


  return {
    init: init
  };
}.bind(site, jQuery)();
"use strict";

// Namespacing
window.site = window.site || {}; // Side panel - opened / close

site.TicketsAjaxCart = function TicketsAjaxCart($) {
  'use strict';
  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false,
      // $addForm        = $('[data-ajax="ticketAddToCart"]'),
  // $removeForm     = $('[data-ajax="removeFromCart"]'),
  // $updateQtyForm  = $('[data-ajax="updateQty"]'),
  $cartPreview = $('#cart-preview'),
      $cartQty = $('[data-cart-qty]'),
      $passesOptions = $('#checkout-cart-form'),
      $locationAlerts = $('[data-location-alerts]'),
      $locationProducts = $('[data-location-products]'),
      $ticketSelector = $('[action-ticket-select]'); // isUpdating = false;

  /**
   * Initializes the class.
   * @public
   */

  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true; // updateCardMatrix()

    var $optionChoices = $('[action-option-value]');
    $optionChoices.on('change', function (e) {
      this.closest('.location-form').querySelector('[data-options-' + this.dataset.optionValue + ']').value = this.value;

      if (this.classList.contains('duration-select')) {
        var isSnowGo = this.options[this.selectedIndex].dataset.rentalSnogo;
        var $skigoNotice = $(this).parents('.card-body').find('.rental-skigo-notice');

        if (isSnowGo == 0) {
          $skigoNotice.hide();
        } else {
          $skigoNotice.show();
        }
      }
    }); //Code Promo validation

    var promoInput = document.querySelector('.promo-input');
    var redirectInput = document.querySelector('.redirect-input');
    var promoSubmit = document.querySelector('.promo-submit');
    var mainSubmitBtn = document.querySelector('.main-submit');

    if (promoInput) {
      var oldPromoData = promoInput.value;
      var cleanedOldPromoData = oldPromoData.trim(); //Check on keyup if the field is filled, if yes, send redirect back to cart

      promoInput.addEventListener('keyup', function (e) {
        var promoData = promoInput.value;
        var cleanedPromoData = promoData.trim();
        var buttonText = ['Passer au paiement', 'Appliquer le code promo'];

        if (site.App.config('locale') === 'en') {
          buttonText = ['Go to payment', 'Apply promo code'];
        }

        if (cleanedPromoData.length > 0 && cleanedOldPromoData !== cleanedPromoData) {
          promoSubmit.disabled = false;
          redirectInput.value = promoInput.dataset.applyUrl;
          mainSubmitBtn.innerText = buttonText[1];
        } else {
          promoSubmit.disabled = true;
          redirectInput.value = promoInput.dataset.paymentUrl;
          mainSubmitBtn.innerText = buttonText[0];
        }
      });
    } // Initiate AjaxCart
    // Add To Cart Action


    addToCart(); // Remove To Cart Action

    removeFromCart(); // Update Qty Action

    updateQty(); // Update Ticket Cards Action

    updateCardData();
    return true;
  };

  var addToCart = function addToCart() {
    console.log("Prep AddToCart");
    $('body').on("submit", '[data-ajax="ticketAddToCart"]', function (event) {
      event.preventDefault();
      var $form = $(this);
      var locationDateS = $('#pickupS').val();
      var locationSizeS = $('#sizeS').val();
      var locationHeightS = $('#heightS').val();
      var locationAgeS = $('#ageS').val();
      var locationSexeS = $('#genderS').val();
      var locationDateP = $('#pickupP').val();
      var locationSizeP = $('#sizeP').val();
      var locationHeightP = $('#heightP').val();
      var locationAgeP = $('#ageP').val();
      var locationSexeP = $('#genderP').val();
      var addingError = false;
      var errorType = 'outOfStock';
      var addingTicketsError = false;
      var ticketErrorType = 'closed';
      var ticketDate = $form.find('input[name="fields[orderTicketsDay]"]').val();
      var ticketQty = $form.find('input[name="qty"]').val();
      var ticketId = $form.find('input[name="purchasableId"]').val();
      var cartTicketsDay = $('[data-qty-tickets-day]');
      var cartTicketsNight = $('[data-qty-tickets-night]');
      var cartTicketsMorning = $('[data-qty-tickets-morning]');
      var totalCartTicketsDay = 0;
      var totalCartTicketsNight = 0;
      var totalCartMorning = 0;
      cartTicketsDay.each(function () {
        totalCartTicketsDay += parseFloat($(this).text());
      });
      cartTicketsNight.each(function () {
        totalCartTicketsNight += parseFloat($(this).text());
      });
      cartTicketsMorning.each(function () {
        totalCartMorning += parseFloat($(this).text());
      });

      if ($form.hasClass('is-location-form-S')) {
        var skus = '';
        $.ajax({
          type: 'get',
          url: '/site/getSkis?date=' + locationDateS + '&size=' + locationHeightS + '&age=' + locationAgeS,
          dataType: 'json',
          async: false,
          success: function success(res) {
            if (res['status']) {
              errorType = res['status'];
              addingError = true;
            } else {
              skus = res['sku'];
              var totalQty = 1;
              $('.location-cart-S').each(function (e) {
                var cartHeight = $(this).find('.pickup-height-S').val();

                if (locationHeightS == cartHeight) {
                  var cartQty = $(this).find('.pickup-qty-S').val();
                  console.log(cartQty);
                  totalQty = parseInt(totalQty) + parseInt(cartQty);
                }
              });

              if (totalQty > res['qty'] || isNaN(totalQty)) {
                addingError = true;
                errorType = res['status'];
              }
            }
          }
        });
        $.ajax({
          type: 'get',
          url: '/site/getBottes?date=' + locationDateS + '&size=' + locationSizeS + '&age=' + locationAgeS,
          dataType: 'json',
          async: false,
          success: function success(res) {
            if (res['status']) {
              addingError = true;
              errorType = res['status'];
            } else {
              skus = skus + ',' + res['sku'];
              var totalQty = 1;
              $('.location-cart-S').each(function (e) {
                var cartSize = $(this).find('.pickup-size-S').val();

                if (locationSizeS == cartSize) {
                  console.log('Same Sizes');
                  var cartQty = $(this).find('.pickup-qty-S').val();
                  console.log('--Cart QTY ---', cartQty);
                  totalQty = parseInt(totalQty) + parseInt(cartQty);
                  console.log('--Total QTY ---', totalQty);
                }
              });

              if (totalQty > res['qty']) {
                addingError = true;
              }
            }
          }
        });
        $('.skiSkus').val(skus);
      }

      if ($form.hasClass('is-location-form-P')) {
        var skus = '';
        $.ajax({
          type: 'get',
          url: '/site/getPlanches?date=' + locationDateP + '&size=' + locationHeightP,
          dataType: 'json',
          async: false,
          success: function success(res) {
            if (res['status']) {
              errorType = res['status'];
              addingError = true;
            } else {
              skus = res['sku'];
              var totalQty = 1;
              $('.location-cart-P').each(function (e) {
                var cartHeight = $(this).find('.pickup-height-P').val();

                if (locationHeightP == cartHeight) {
                  var cartQty = $(this).find('.pickup-qty-P').val();
                  totalQty = parseInt(totalQty) + parseInt(cartQty);
                }
              });

              if (totalQty > res['qty']) {
                addingError = true;
                errorType = res['status'];
              }
            }
          }
        });
        $.ajax({
          type: 'get',
          url: '/site/getBottesPlanches?date=' + locationDateP + '&size=' + locationSizeP + '&age=' + locationAgeP,
          dataType: 'json',
          async: false,
          success: function success(res) {
            if (res['status']) {
              errorType = res['status'];
              addingError = true;
            } else {
              skus = skus + ',' + res['sku'];
              var totalQty = 1;
              $('.location-cart-P').each(function (e) {
                var cartSize = $(this).find('.pickup-size-P').val();

                if (locationSizeP == cartSize) {
                  var cartQty = $(this).find('.pickup-qty-P').val();
                  totalQty = parseInt(totalQty) + parseInt(cartQty);
                }
              });

              if (totalQty > res['qty']) {
                addingError = true;
                errorType = res['status'];
              }
            }
          }
        });
        $('.plancheSkus').val(skus);
      }

      if ($form.hasClass('is-location-form-S') || $form.hasClass('is-location-form-P')) {
        $.ajax({
          type: 'get',
          url: '/site/getRemainingRentals?date=' + ticketDate,
          dataType: 'json',
          async: false,
          success: function success(res) {
            if (res['isClosed'] == true || res['isSoon'] == true) {
              addingError = true;
              errorType = res['unavailable'];
            } else {
              var totalQty = 1;
              var locationCartP = document.querySelectorAll('.location-cart-P');
              locationCartP.forEach(function (boardElement) {
                var cartHeight = boardElement.querySelector('.pickup-height-P').value; // if(locationHeightP == cartHeight) {

                var cartQty = boardElement.querySelector('.pickup-qty-P').value;
                totalQty = parseInt(totalQty) + parseInt(cartQty); // }
              });
              var locationCartS = document.querySelectorAll('.location-cart-S');
              locationCartS.forEach(function (skiElement) {
                var cartHeight = skiElement.querySelector('.pickup-height-S').value; // if(locationHeightS == cartHeight) {

                var cartQty = skiElement.querySelector('.pickup-qty-S').value;
                totalQty = parseInt(totalQty) + parseInt(cartQty); // }
              });
              var remainingTicket = res['limit'] - totalQty;

              if (remainingTicket < 0) {
                console.log('Remaining Ticket error');
                addingError = true;
                errorType = res['status'];
              }
            }
          }
        });
        var cartIdInput = $form.find('input[name="cartId"]');
        var pickupDateInput = $form.find('select[data-option-value="pickup"]');

        if (cartIdInput && pickupDateInput) {
          var cartId = cartIdInput.val();
          var pickupDate = pickupDateInput.val();
          $.ajax({
            type: 'get',
            url: '/site/getCanRentHour?rentalDate=' + pickupDate + '&cartId=' + cartId,
            dataType: 'json',
            async: false,
            success: function success(res) {
              if (!res['response']) {
                addingError = true;
                errorType = res['errorType'];
              }
            }
          });
        }
      }

      if ($form.hasClass('is-ticket-form')) {
        console.log('is in form');
        $.ajax({
          type: 'get',
          url: '/site/getRemainingTickets?date=' + ticketDate,
          dataType: 'json',
          async: false,
          success: function success(res) {
            if (res['isClosed'] == true) {
              console.log('closed');
              addingTicketsError = true;
            } else {
              var isDay = $('.ticket-day').hasClass('is-active');
              var isMorning = $('.ticket-morning').hasClass('is-active');
              var isNight = $('.ticket-night').hasClass('is-active');

              if (isDay) {
                var remainingTicket = res['limitDay'] - ticketQty - totalCartTicketsDay;
              } else if (isNight) {
                var remainingTicket = res['limitNight'] - ticketQty - totalCartTicketsNight;
              } else if (isMorning) {
                var remainingTicket = res['limitMorning'] - ticketQty - totalCartMorning;
              }

              if (remainingTicket < 0) {
                console.log('sold out');
                addingTicketsError = true;
                ticketErrorType = 'sold-out';
              }
            }
          }
        });
      }

      if (addingTicketsError) {
        if (site.App.config('locale') === 'en') {
          var swalContent = ['Warning', 'There are not enough tickets for this date to fulfill your request', 'The ticketing is or has been closed for this date.'];
        } else {
          var swalContent = ['Attention', 'Il n\'y a pas assez de billets disponible pour cette date pour combler votre demande', 'La billetterie est / a été fermée pour cette date'];
        }

        swal({
          title: swalContent[0],
          text: ticketErrorType == 'sold-out' ? swalContent[1] : swalContent[2],
          type: "warning",
          showCancelButton: false,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Ok",
          closeOnConfirm: true,
          html: false
        });
      } else if (addingError) {
        $('[data-check-validation]').prop('disabled', true).addClass('is-disabled');
        $('[data-check-validation].is-disabled').on('click', function (e) {
          e.preventDefault();
        });

        if (site.App.config('locale') === 'en') {
          var swalContent = ['Warning', 'The equipments is not available for the chosen date. Please choose another date.', 'The quantity of equipments you asked for is not available for the chosen date. Please choose another date or reduce the quantity.', 'An error occurred while verifying available equipments. Please make sure that the info submitted are correct!', 'The equipments is not available for the chosen hour. Please choose another hour.', 'The equipments are not available at the moment that you have chosen. Please try again.'];
        } else {
          var swalContent = ['Attention', 'Les équipements ne sont pas disponible pour la date choisie. Veuillez changer de date.', 'La quantité demandé pour les équipements ne sont pas disponible pour la date choisie. Veuillez changer de date ou réduire la quantité.', 'Une erreur est survénu lors de la vérification des équipements. Veuillez vous assurer que vous avez entré les bonnes informations!', 'Les équipements ne sont pas disponible pour la plage horaire choisie. Veuillez changer de plage horaire.', 'L\'équipement n\'est pas disponible au moment que vous avez sélectionné. Veuillez réessayer.'];
        }

        console.log('---Add Error---');
        console.log(errorType);
        var errorMSG = swalContent[2];

        if (errorType == 'unavailable') {
          errorMSG = swalContent[1];
        } else if (errorType == 'submitError') {
          errorMSG = swalContent[3];
        } else if (errorType == 'unavailableHour') {
          errorMSG = swalContent[4];
        } else if (errorType == 'outOfStock') {
          errorMSG = swalContent[5];
        }

        swal({
          title: swalContent[0],
          text: errorMSG,
          type: "warning",
          showCancelButton: false,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Ok",
          closeOnConfirm: true,
          html: false
        });
      } else {
        $('[data-check-validation]').prop('disabled', false).remove('is-disabled');
        $('.sidepanel-overlay').addClass('opened is-loading'); // Clear Cart if needed

        if ($('[data-clear-cart]').length) {
          var $clear = $('[data-clear-cart]');
          $.ajax({
            type: 'post',
            url: $('[name="action"]', $clear).val(),
            data: $clear.serializeArray(),
            success: function success(res) {
              sendFormByAjax($form);
              $clear.remove();
            }
          });
        } else {
          sendFormByAjax($form);
        }
      }
    });
  };

  var updateCardMatrix = function updateCardMatrix($orderId) {
    var $inputs = $('[data-matrix-input]');
    var blockDatas = [];
    console.log('Checking Order Id :: ' + $orderId);
    console.log('triggered matrix');
    $.ajax({
      type: 'get',
      url: '/site/getLatestMatrixId?orderId=' + $orderId,
      dataType: 'json',
      async: false,
      success: function success(res) {
        console.log('--Reading The data---');
        console.log('Res :: ' + res);
        blockDatas = res;
      }
    });
    console.log('Block Datas ::');
    console.log(blockDatas);
    var $form = $('.location-form');
    var matrixBlocks = '<input type="hidden" name="fields[customTickets][' + blockDatas['id'] + '][type]" value="location">' + '<input type="hidden" name="fields[customTickets][' + blockDatas['id'] + '][enabled]" value="1">' + '<input type="hidden" name="fields[customTickets][' + blockDatas['id'] + '][fields][locationActivity]" value="' + blockDatas['activity'] + '">' + '<input type="hidden" name="fields[customTickets][' + blockDatas['id'] + '][fields][lineItemUid]" value="' + blockDatas['uid'] + '">' + '<input type="hidden" name="fields[customTickets][' + blockDatas['id'] + '][fields][locationSkus]" value="' + blockDatas['skus'] + '">' + '<input type="hidden" name="fields[customTickets][' + blockDatas['id'] + '][fields][locationSex]" value="' + blockDatas['gender'] + '">' + '<input type="hidden" name="fields[customTickets][' + blockDatas['id'] + '][fields][locationAge]" value="' + blockDatas['age'] + '">' + '<input type="hidden" name="fields[customTickets][' + blockDatas['id'] + '][fields][locationDuration]" value="' + blockDatas['duration'] + '">' + '<input type="hidden" name="fields[customTickets][' + blockDatas['id'] + '][fields][locationWeight]" value="' + blockDatas['weight'] + '">' + '<input type="hidden" name="fields[customTickets][' + blockDatas['id'] + '][fields][locationHeight]" value="' + blockDatas['height'] + '">' + '<input type="hidden" name="fields[customTickets][' + blockDatas['id'] + '][fields][locationSize]" value="' + blockDatas['size'] + '">' + '<input type="hidden" name="fields[customTickets][' + blockDatas['id'] + '][fields][locationPickup]" value="' + blockDatas['pickupDate'] + '">';
    $('[data-options-unique]').val(blockDatas['activity'] + '-' + Date.now());
    $('.dynamic-cart-id').val(blockDatas['cartId']); // $('[data-matrix-input]').val(parseInt($('[data-matrix-sort-order]').val()) + 1);

    $form.append(matrixBlocks);
  };

  var removeFromCart = function removeFromCart() {
    $('body').on("submit", '[data-ajax="ticketRemoveFromCart"]', function (event) {
      event.preventDefault();
      var $form = $(this);
      sendFormByAjax($form);
    });
  };

  var updateQty = function updateQty() {
    $('body').on("submit", '[data-ajax="updateQtyTicket"]', function (event) {
      event.preventDefault();
      var $form = $(this);
      var locationDateS = $('.pickup-date-S', $form).val();
      var locationSizeS = $('.pickup-size-S', $form).val();
      var locationHeightS = $('.pickup-height-S', $form).val();
      var locationAgeS = $('.pickup-age-S', $form).val();
      var locationSexeS = $('.pickup-gender-S', $form).val();
      var locationDateP = $('.pickup-date-P', $form).val();
      var locationSizeP = $('.pickup-size-P', $form).val();
      var locationHeightP = $('.pickup-height-P', $form).val();
      var locationAgeP = $('.pickup-age-P', $form).val();
      var locationSexeP = $('.pickup-gender-P', $form).val();
      var currentQty = $('.cart-qty-location').val();
      var addingError = false;
      var errorType = 'outOfStock';

      if ($form.hasClass('location-cart-S')) {
        $.ajax({
          type: 'get',
          url: '/site/getSkis?date=' + locationDateS + '&size=' + locationHeightS + '&age=' + locationAgeS,
          dataType: 'json',
          async: false,
          success: function success(res) {
            if (res['status']) {
              $('[action-locationS-error]').show();
              addingError = true;
              errorType = res['status'];
            } else if (res['qty'] - currentQty < 0) {
              addingError = true;
            }
          }
        });
        $.ajax({
          type: 'get',
          url: '/site/getBottes?date=' + locationDateS + '&size=' + locationSizeS + '&age=' + locationAgeS,
          dataType: 'json',
          async: false,
          success: function success(res) {
            if (res['status']) {
              $('[action-locationS-error]').show();
              addingError = true;
              errorType = res['status'];
            } else if (res['qty'] - currentQty < 0) {
              addingError = true;
            }
          }
        });
      }

      if ($form.hasClass('location-cart-P')) {
        $.ajax({
          type: 'get',
          url: '/site/getPlanches?date=' + locationDateP + '&size=' + locationHeightP,
          dataType: 'json',
          async: false,
          success: function success(res) {
            if (res['status']) {
              $('[action-location-cartP-error]').show();
              addingError = true;
              errorType = res['status'];
            } else if (res['qty'] - currentQty < 0) {
              addingError = true;
            }
          }
        });
        $.ajax({
          type: 'get',
          url: '/site/getBottesPlanches?date=' + locationDateP + '&size=' + locationSizeP + '&age=' + locationAgeP,
          dataType: 'json',
          async: false,
          success: function success(res) {
            if (res['status']) {
              $('[action-location-cartP-error]').show();
              addingError = true;
              errorType = res['status'];
            } else if (res['qty'] - currentQty < 0) {
              addingError = true;
            }
          }
        });
      }

      if (addingError) {
        $('[data-check-validation]').prop('disabled', true).addClass('is-disabled');
        $('[data-check-validation].is-disabled').on('click', function (e) {
          e.preventDefault();
        });

        if (site.App.config('locale') === 'en') {
          var swalContent = ['Warning', 'The equipments is not available for the chosen date. Please choose another date.', 'The quantity of equipments you asked for is not available for the chosen date. Please choose another date or reduce the quantity.', 'An error occurred while verifying available equipments. Please make sure that the info submitted are correct!'];
        } else {
          var swalContent = ['Attention', 'Les équipements ne sont pas disponible pour la date choisie. Veuillez changer de date.', 'La quantité demandé pour les équipements ne sont pas disponible pour la date choisie. Veuillez changer de date ou réduire la quantité.', 'Une erreur est survénu lors de la vérification des équipements. Veuillez vous assurer que vous avez entré les bonnes informations!'];
        }

        console.log('---Update Error---');
        console.log(errorType);
        var errorMSG = swalContent[2];

        if (errorType == 'unavailable') {
          errorMSG = swalContent[1];
        } else if (errorType == 'submitError') {
          errorMSG = swalContent[3];
        }

        swal({
          title: swalContent[0],
          text: errorMSG,
          type: "warning",
          showCancelButton: false,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Ok",
          closeOnConfirm: true,
          html: false
        });
      } else {
        $('[data-check-validation]').prop('disabled', false).remove('is-disabled');
        sendFormByAjax($form);
      }
    });
  };

  var sendFormByAjax = function sendFormByAjax($form) {
    console.log('init send ajax'); // Disable multiple form send

    $('[type="submit"]', $form).prop('disabled', true);
    var persistLang = '';

    if ($('html').attr('lang') == 'en') {
      persistLang = '/en';
    }

    console.log(persistLang + $('[name="action"]', $form).val());
    console.log('after check');
    console.log($('[name="action"]', $form).val());
    console.log($form.serializeArray());
    $.ajax({
      type: 'post',
      url: $('[name="action"]', $form).val(),
      data: $form.serializeArray(),
      dataType: 'json',
      success: function success(response) {
        if (!response.success) {
          alert(response.error); // Re-Enable send button

          $('[type="submit"]', $form).prop('disabled', false);
          return false;
        } // Refresh cart small components


        var updatedCart = response.cart;
        $cartQty.text(updatedCart.totalQty); // Refresh Cart preview
        // Load new state of cart-preview.twig

        if (updatedCart.totalQty == 0) {
          window.location.replace("/");
        } else {
          $cartPreview.load("/cart-ajax.twig", {
            "refererPage": window.location.href
          });
          $passesOptions.load("/passes-options.twig", {
            "refererPage": window.location.href
          });
          $locationAlerts.load("/location-index.twig" + " [data-location-alerts]", {
            "refererPage": window.location.href
          });
        }

        console.log('Order ID :: ' + response['cart']['id']);
        setTimeout(function () {
          updateCardMatrix(response['cart']['id']);
        }, 1000); // Open Cart Preview with loading

        window.site.SidePanelCart.openPanel(true); // Re-Enable send button

        $('[type="submit"]', $form).prop('disabled', false); // location.reload();

        return true;
      }
    });
  };

  var updateCardData = function updateCardData() {
    $ticketSelector.on('change', function (e) {
      e.preventDefault();
      var $parentContainer = $(this).parents('li');
      var $purchasableIdField = $parentContainer.find('[action-purchasable-id]'),
          $originalPriceText = $parentContainer.find('[action-original-price]'),
          $percentRebate = $parentContainer.find('[action-percent-rebate]'),
          $ticketSubtitle = $parentContainer.find('[action-ticket-subtitle]'),
          $skigoNotice = $parentContainer.find('[action-skigo-notice]'),
          $ticketPriceText = $parentContainer.find('[action-ticket-price]');
      var chosenPurchasableId = $(this).val(),
          chosenPrice = this.options[this.selectedIndex].dataset.ticketPrice,
          chosenPriceRebate = this.options[this.selectedIndex].dataset.ticketRebate,
          chosenRebatePercent = this.options[this.selectedIndex].dataset.ticketRebatePercent,
          chosenSubtitle = this.options[this.selectedIndex].dataset.ticketSubtitle,
          isSnowGo = this.options[this.selectedIndex].dataset.ticketSnogo,
          chosenIsOnSale = this.options[this.selectedIndex].dataset.ticketOnSale,
          chosenSalePrice = this.options[this.selectedIndex].dataset.ticketSalePrice;
      $purchasableIdField.val(chosenPurchasableId);
      $ticketPriceText.html(chosenPrice + '<span>+ tx</span>');
      $ticketSubtitle.html(chosenSubtitle); //If it's a sale, show the sale design instead

      if (chosenIsOnSale == 1) {
        $ticketPriceText.html(chosenSalePrice + '<span>+ tx</span>');
        $originalPriceText.removeClass('is-blank').text(chosenPrice);
      } else {
        //else show default design
        $ticketPriceText.html(chosenPrice + '<span>+ tx</span>');
        $originalPriceText.addClass('is-blank');
      }

      if (chosenPriceRebate == 0) {
        $percentRebate.hide();
        $originalPriceText.addClass('is-blank');
      } else {
        var lang = document.querySelector('html').attr('lang');
        $percentRebate.show().text(chosenRebatePercent == '24' ? lang === 'en' ? 'Presale' : 'Prévente' : chosenRebatePercent + '%');
        $originalPriceText.removeClass('is-blank').text(chosenPrice);
      }

      if (isSnowGo == 0) {
        $skigoNotice.hide();
      } else {
        $skigoNotice.show();
      }
    });
  };

  return {
    init: init
  };
}.bind(site, jQuery)();
"use strict";

window.site = window.site || {};
/**
 * Preview uploaded image on user dashboart without uploading
 * @class UploadedImagePreview
 * @static
 */

site.UploadedImagePreview = function UploadedImagePreview($) {
  'use strict';
  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false,
      containerSelector = '.profile-avatar .input-avatar',
      inputSelector = '.profile-avatar [name="userPhoto"]';
  /**
   * Initializes the class.
   * @public
   */

  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true; // Initiate image roeview

    imagePreview();
    return true;
  };
  /**
   * Handle trigger submit button
   * @public
   */


  var imagePreview = function imagePreview() {
    console.log('hit');
    $(inputSelector).change(function () {
      readURL(this);
    });
  };
  /**
   * Image preview function
   * @public
   */


  var readURL = function readURL(input) {
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      console.log(input.files[0]);

      reader.onload = function (e) {
        $(containerSelector).css('background-image', 'url(' + e.target.result + ')').css('background-size', 'cover');
      };

      reader.readAsDataURL(input.files[0]);
    }
  }; // Expose public methods & properties


  return {
    init: init
  };
}(jQuery);
"use strict";

// Namespacing
window.site = window.site || {}; // Component

site.Skishop = function Skishop($) {
  'use strict';
  /**
   * JQuery variables
   * @private
   */

  var categoriesExpandables = [].slice.call(document.querySelectorAll('.categories-group.is-expendable'));
  var categoriesForm = document.querySelector('#skishopCatsForm');
  var shippingAddressZipInput = document.querySelector('input[name="billingAddress[zipCode]"]');
  var billingAddressZipInput = document.querySelector('input[name="shippingAddress[zipCode]"]');
  var shippingPriceLabel = document.querySelector('.label-body.shipping-price');
  var filtersMobileTrigger = document.querySelector('[data-trigger-filters]');
  var filtersMobileClose = document.querySelector('[data-mobile-filters-close]');
  var filtersMobileModal = document.querySelector('.skipshop-mobile-filters');
  var filtersMobileClear = document.querySelector('[data-filters-mobile-clear]');
  var sortyBySelect = document.querySelector('.toolbar-sorting select');
  var productSizeSelect = document.querySelector('.product-details-container .choices-select.is-size');
  var productThumbs = [].slice.call(document.querySelectorAll('[data-product-thumb]'));
  var productThumbsWrapper = document.querySelector('.product-details-container .thumbnails-wrapper');
  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;
  /**
   * Initializes the class.
   * @public
   */

  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    if (productThumbs) {
      if (productThumbsWrapper) {
        dragSlider('.product-details-container .thumbnails-wrapper');
        window.addEventListener('resize', function () {
          dragSlider('.product-details-container .thumbnails-wrapper');
        });
      }

      var productVisualPaneljQuery = $('.product-details-container .visual-panel');

      if (productVisualPaneljQuery) {
        productVisualPaneljQuery.elevateZoom();
      }

      productThumbs.forEach(function (thumb) {
        thumb.addEventListener('click', function (e) {
          e.preventDefault(); // const prevElement = document.querySelector('.product-details-container .thumbnails-panel');

          var oldImage = document.querySelector('.product-details-container .visual-panel-wrapper .visual-panel');
          var oldImageParent = document.querySelector('.product-details-container .visual-panel-wrapper');
          oldImageParent.removeChild(oldImage);
          var newImage = document.createElement('img');
          newImage.src = thumb.src;
          newImage.dataset.zoomImage = thumb.src;
          newImage.classList.add('visual-panel');
          oldImageParent.appendChild(newImage);
          productVisualPaneljQuery = $('.product-details-container .visual-panel');

          if (productVisualPaneljQuery) {
            productVisualPaneljQuery.elevateZoom();
          }
        });
      });
    }

    var limitsProductsByPageRadios = [].slice.call(document.querySelectorAll('.toolbar-limits input[type="radio"]'));

    if (limitsProductsByPageRadios.length > 0) {
      limitsProductsByPageRadios.forEach(function (radioBtn) {
        radioBtn.addEventListener('change', function (e) {
          var hiddenLimitInput = categoriesForm.querySelector('input[name="limit"]');

          if (hiddenLimitInput) {
            hiddenLimitInput.value = radioBtn.value;
            categoriesForm.submit();
          }
        });
      });
    }

    if (categoriesExpandables) {
      var queryString = window.location.search;
      var urlParams = new URLSearchParams(queryString);
      categoriesExpandables.forEach(function (catExpendable) {
        var catHeader = catExpendable.querySelector('.categories-title');
        catHeader.addEventListener('click', function (e) {
          e.preventDefault();
          catExpendable.classList.toggle('is-active');
        });

        if (urlParams.get('type') && catExpendable.getAttribute('data-type') === 'type') {
          catExpendable.classList.add('is-active');
        }

        if (urlParams.get('colors[]') && catExpendable.getAttribute('data-type') === 'colors') {
          catExpendable.classList.add('is-active');
        }

        if (urlParams.get('sizes[]') && catExpendable.getAttribute('data-type') === 'sizes') {
          catExpendable.classList.add('is-active');
        }
      });
    }

    if (filtersMobileTrigger) {
      filtersMobileTrigger.addEventListener('click', function (e) {
        e.preventDefault();
        filtersMobileModal.classList.add('is-active');
        document.querySelector('body').style.overflow = 'hidden';
      });
    }

    if (filtersMobileClose) {
      filtersMobileClose.addEventListener('click', function (e) {
        e.preventDefault();
        filtersMobileModal.classList.remove('is-active');
        document.querySelector('body').style.overflow = 'auto';
      });
    }

    if (filtersMobileClear) {
      filtersMobileClear.addEventListener('click', function (e) {
        e.preventDefault();
        var url = window.location.href.split('?')[0];
        window.location.href = url;
      });
    }

    if (sortyBySelect) {
      sortyBySelect.addEventListener('change', function (e) {
        var hiddenSortByInput = categoriesForm.querySelector('input[name="sortBy"]');

        if (hiddenSortByInput) {
          hiddenSortByInput.value = sortyBySelect.value;
          categoriesForm.submit();
        }
      }, true);
    }

    if (categoriesForm) {
      var filters = [].slice.call(categoriesForm.querySelectorAll('label'));
      var filtersCheckbox = [].slice.call(categoriesForm.querySelectorAll('input'));
      filtersCheckbox.forEach(function (checkbox) {
        checkbox.addEventListener('click', function (e) {
          e.stopPropagation();
        });
      });
      filters.forEach(function (filter) {
        filter.addEventListener('click', function (e) {
          e.preventDefault();
          var input = filter.querySelector('input');
          input.click();
          categoriesForm.submit();
        });
      });
    }

    if (productSizeSelect) {
      productSizeSelect.addEventListener('change', function (e) {
        var productFormBtn = document.querySelector('[data-ajax="ticketAddToCart"] .button');
        productFormBtn.disabled = false;
      }, true);
    }

    if (shippingAddressZipInput) {
      shippingAddressZipInput.addEventListener('input', function (e) {
        var parent = shippingAddressZipInput.closest('.BillingAddress');
        var value = shippingAddressZipInput.value.replace(/ /g, '');

        if (!parent.classList.contains('hidden') && value !== '' && value.length === 6) {
          sendFormAjax(value);
        }
      }, true);
    }

    if (billingAddressZipInput) {
      billingAddressZipInput.addEventListener('input', function (e) {
        var billingAddressContainer = document.querySelector('.BillingAddress');
        var value = billingAddressZipInput.value.replace(/ /g, '');

        if (billingAddressContainer.classList.contains('hidden') && value !== '' && value.length === 6) {
          sendFormAjax(value);
        }
      }, true);
    }

    inited = true;
    return true;
  };

  var insertAfter = function insertAfter(referenceNode, newNode) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  };

  var dragSlider = function dragSlider(selector) {
    var el = document.querySelector(selector);
    var x = 0,
        y = 0,
        top = 0,
        left = 0;

    var draggingFunction = function draggingFunction(e) {
      document.addEventListener('mouseup', function () {
        document.removeEventListener("mousemove", draggingFunction);
      });
      el.scrollLeft = left - e.pageX + x;
      el.scrollTop = top - e.pageY + y;
    };

    el.addEventListener('mousedown', function (e) {
      e.preventDefault();
      y = e.pageY;
      x = e.pageX;
      top = el.scrollTop;
      left = el.scrollLeft;
      document.addEventListener('mousemove', draggingFunction);
    });
  };

  var sendFormAjax = function sendFormAjax(value) {
    var shippingForm = document.querySelector('form#shippingRatesForm');
    shippingForm.querySelector('[name="postalCode"]').value = value;
    $.ajax({
      type: 'get',
      url: '/site/getShippingRates',
      dataType: 'json',
      data: $('form#shippingRatesForm').serializeArray(),
      success: function success(res) {
        var shippingPriceEl = document.querySelector('.shipping-price');
        var cartShippingPriceEl = document.querySelector('.sidepanel-footer-subtotal .price.is-shipping');
        var updatedRate = '';

        if (shippingPriceEl) {
          var _finalRate = res === 0 ? shippingPriceEl.dataset.rateError : res[0].replace('.', ',');

          console.log(res);

          if (res === 0) {
            updatedRate = '(' + _finalRate + ')';
          } else {
            if (window.site.App.config('locale') === 'fr') {
              updatedRate = '(' + _finalRate + ' $)';
            } else {
              updatedRate = '($ ' + _finalRate + ')';
            }
          }

          shippingPriceEl.querySelector('strong').textContent = updatedRate;
        }

        if (cartShippingPriceEl) {
          if (res === 0) {
            updatedRate = 'N/A';
          } else {
            if (window.site.App.config('locale') === 'fr') {
              updatedRate = finalRate + ' $';
            } else {
              updatedRate = '$ ' + finalRate;
            }
          }

          cartShippingPriceEl.textContent = updatedRate;
        }
      }
    });
  }; // Expose public methods & properties


  return {
    init: init
  };
}.bind(site, jQuery)();
"use strict";

// Namespacing
window.site = window.site || {}; // Component

site.PassMultiAdd = function PassMultiAdd($) {
  'use strict';
  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;
  /**
   * Initializes the class.
   * @public
   */

  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    console.log("page script");

    if ($('#subtotal-val').text() == '0') {
      $('.sidebar-button button[type=submit]').prop("disabled", true);
      $('.sidebar-button button[type=submit]').css('cursor', 'not-allowed');
    } else {
      $('.sidebar-button button[type=submit]').prop("disabled", false);
      $('.sidebar-button button[type=submit]').css('cursor', 'pointer');
    } // Clear Cart if needed


    if ($('[data-clear-cart]').length) {
      var $clear = $('[data-clear-cart]');
      $.ajax({
        type: 'post',
        url: $('[name="action"]', $clear).val(),
        data: $clear.serializeArray()
      });
      $clear.remove();
    }

    if ($('.passes-details.minOf2').length) {
      $('.sidebar-button button[type=submit]').prop("disabled", true);
    }

    var $qtyBtns = [].slice.call(document.querySelectorAll('.passes-details .custom-quantity .button'));
    $qtyBtns.forEach(function ($qtyBtn) {
      $qtyBtn.addEventListener('click', function (e) {
        var productsubtotal = 0;
        var productdiscount = 0;
        var qty = 0;
        var price = 0;
        var differnce = 0;
        var presubtotal = 0;
        var prediscount = 0;
        var input = $qtyBtn.parentElement.querySelector('input');
        var oldQty = parseInt(input.value);
        var newQty = oldQty;

        if ($qtyBtn.dataset.type == 'plus') {
          newQty = oldQty + 1;

          if (newQty > input.getAttribute('max')) {
            newQty = oldQty;
          }
        } else {
          newQty = oldQty - 1;

          if (newQty < input.getAttribute('min')) {
            newQty = oldQty;
          }
        }

        input.value = newQty;
        var $qtyInputs = [].slice.call(document.querySelectorAll('.passes-details .custom-quantity input'));
        $qtyInputs.forEach(function ($qtyInput) {
          qty = parseInt($qtyInput.value);
          price = $qtyInput.dataset.price;
          differnce = $qtyInput.dataset.differnce;
          productsubtotal = parseFloat(productsubtotal) + parseFloat(price * qty);
          productdiscount = parseFloat(productdiscount) + parseFloat(differnce * qty);
        });
        var subtotal = parseFloat(productsubtotal) + parseFloat(presubtotal);
        var discount = parseFloat(productdiscount) + parseFloat(prediscount);
        subtotal = subtotal.toFixed(2).replace(".", ",");
        discount = discount.toFixed(2).replace(".", ",");
        $('#subtotal-val').html(subtotal);
        $('#discount-val').html(discount);

        if ($('.passes-details.minOf2').length) {
          var totalQty = 0;
          var $customQtyInputs = [].slice.call(document.querySelectorAll('.custom-quantity input'));
          $customQtyInputs.forEach(function ($customQtyInput) {
            totalQty += parseInt($customQtyInput.value);
          });

          if (totalQty < 2) {
            $('.sidebar-button button[type=submit]').prop("disabled", true);
            $('.sidebar-button button[type=submit]').css('cursor', 'not-allowed');
          } else {
            $('.sidebar-button button[type=submit]').prop("disabled", false);
            $('.sidebar-button button[type=submit]').css('cursor', 'pointer');
          }
        } else {
          if ($('.sidebar-button button[type=submit]').hasClass('enable-button')) {
            $('.sidebar-button button[type=submit]').prop("disabled", false);
            $('.sidebar-button button[type=submit]').css('cursor', 'pointer');
          } else {
            if (parseInt(subtotal) == 0) {
              $('.sidebar-button button[type=submit]').prop("disabled", true);
              $('.sidebar-button button[type=submit]').css('cursor', 'not-allowed');
            } else {
              $('.sidebar-button button[type=submit]').prop("disabled", false);
              $('.sidebar-button button[type=submit]').css('cursor', 'pointer');
            }
          }
        }
      });
    });
    inited = true;
    return true;
  }; // Expose public methods & properties


  return {
    init: init
  };
}.bind(site, jQuery)();
"use strict";

window.site = window.site || {};
/**
 * Preview uploaded image on user dashboart without uploading
 * @class UploadedImagePreview
 * @static
 */

site.TicketSelection = function TicketSelection($) {
  'use strict';
  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false,
      btnDay = '.categories-item.is-day',
      btnMorning = '.categories-item.is-morning',
      btnNight = '.categories-item.is-night';
  /**
   * Initializes the class.
   * @public
   */

  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;
    $(btnDay).addClass('is-active'); // $('.tickets-remaining.is-day').show();
    // $('.tickets-remaining.is-night').hide();
    // $('.ticket-day.tickets-list').show();
    // $('.ticket-night.tickets-list').hide();

    $(btnDay).on('click', function (e) {
      e.preventDefault();
      $(this).addClass('is-active');
      $(btnNight).removeClass('is-active');
      $(btnMorning).removeClass('is-active');
      $('.tickets-remaining.is-day').addClass('is-active');
      $('.tickets-remaining.is-night').removeClass('is-active');
      $('.tickets-remaining.is-morning').removeClass('is-active');
      $('.ticket-day.tickets-list').addClass('is-active');
      $('.ticket-night.tickets-list').removeClass('is-active');
      $('.ticket-morning.tickets-list').removeClass('is-active');
    });
    $(btnNight).on('click', function (e) {
      e.preventDefault();
      $(this).addClass('is-active');
      $(btnDay).removeClass('is-active');
      $(btnMorning).removeClass('is-active');
      $('.tickets-remaining.is-day').removeClass('is-active');
      $('.tickets-remaining.is-morning').removeClass('is-active');
      $('.tickets-remaining.is-night').addClass('is-active');
      $('.ticket-day.tickets-list').removeClass('is-active');
      $('.ticket-morning.tickets-list').removeClass('is-active');
      $('.ticket-night.tickets-list').addClass('is-active');
    });
    $(btnMorning).on('click', function (e) {
      e.preventDefault();
      $(this).addClass('is-active');
      $(btnDay).removeClass('is-active');
      $(btnNight).removeClass('is-active');
      $('.tickets-remaining.is-day').removeClass('is-active');
      $('.tickets-remaining.is-night').removeClass('is-active');
      $('.tickets-remaining.is-morning').addClass('is-active');
      $('.ticket-day.tickets-list').removeClass('is-active');
      $('.ticket-night.tickets-list').removeClass('is-active');
      $('.ticket-morning.tickets-list').addClass('is-active');
    });
    return true;
  }; // Expose public methods & properties


  return {
    init: init
  };
}(jQuery);
"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

/* global site */
window.site = window.site || {};
/**
 * Main application class.
 * @class App
 * @static
 */

site.App = function App() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;
  var $openMenu = document.querySelectorAll('.mobile-trigger')[0];
  var $closeMenu = document.querySelectorAll('.close-button')[0];
  var $mobileMenu = document.querySelectorAll('.mobile-nav')[0];
  /**
   * Application config defaults.
   * @private
   * @param config.env     Current server environment
   * @param config.csrf    Security token to submit with forms
   * @param config.csrfName    Security token to submit with forms
   * @param config.locale  Current locale short code
   * @param config.device  Device detection based on browser signature
   * @param config.preview Page is shown through live preview mode
   * @param config.general Settings from general config
   */

  var config = {
    env: 'production',
    csrf: null,
    csrfName: null,
    locale: 'en',
    device: 'desktop',
    preview: false,
    general: {}
  };
  /**
   * Initializes the class.
   * @public
   */

  var init = function init(options) {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;
    var version = document.querySelector('html').getAttribute('code-version');
    console.log(" \uD83C\uDF89 Code Version:%c ".concat(version, " "), 'color: #ff0000');
    $openMenu.addEventListener('click', function (e) {
      e.preventDefault();
      console.log($mobileMenu);
      $mobileMenu.classList.toggle('is-active');
    });
    $closeMenu.addEventListener('click', function (e) {
      e.preventDefault();
      $mobileMenu.classList.toggle('is-active');
    }); // Store application settings

    options = options || {};

    if (options.env) {
      config.env = options.env;
    }

    if (options.csrf) {
      config.csrf = options.csrf;
    }

    if (options.csrfName) {
      config.csrfName = options.csrfName;
    }

    if (options.locale) {
      config.locale = options.locale;
    }

    if (options.device) {
      config.device = options.device;
    }

    if (options.preview) {
      config.preview = options.preview;
    }

    if (options.general) {
      config.general = options.general;
    } // Initialize child classes
    // if (typeof site.Example === 'object') { site.Example.init(); }


    if (_typeof(site.SidePanelCart) === 'object') {
      site.SidePanelCart.init();
    }

    if (_typeof(site.AjaxCart) === 'object') {
      site.AjaxCart.init();
    }

    if (_typeof(site.TicketsAjaxCart) === 'object') {
      site.TicketsAjaxCart.init();
    } // if (typeof site.LocationsAjaxCart === 'object') { site.LocationsAjaxCart.init(); }


    if (_typeof(site.UploadedImagePreview) === 'object') {
      site.UploadedImagePreview.init();
    }

    if (_typeof(site.Datepicker) === 'object') {
      site.Datepicker.init();
    }

    if (_typeof(site.TicketsDatePicker) === 'object') {
      site.TicketsDatePicker.init();
    }

    if (_typeof(site.Content) === 'object') {
      site.Content.init();
    }

    if (_typeof(site.PhoneNumberMask) === 'object') {
      site.PhoneNumberMask.init();
    }

    if (_typeof(site.StickyContent) === 'object') {
      site.StickyContent.init();
    }

    if (_typeof(site.LocationModal) === 'object') {
      site.LocationModal.init();
    }

    if (_typeof(site.Skishop) === 'object') {
      site.Skishop.init();
    }

    if (_typeof(site.TicketSelection) === 'object') {
      site.TicketSelection.init();
    }

    if (_typeof(site.ForfaitsSelection) === 'object') {
      site.ForfaitsSelection.init();
    }

    if (_typeof(site.LocationSkiGo) === 'object') {
      site.LocationSkiGo.init();
    }

    if (_typeof(site.GoogleCaptcha) === 'object') {
      site.GoogleCaptcha.init();
    }

    if (_typeof(site.PassMultiAdd) === 'object') {
      site.PassMultiAdd.init();
    }

    if (_typeof(site.ForfaitsAlert) === 'object') {
      site.ForfaitsAlert.init();
    }

    console.table(options); // Return success

    return true;
  };
  /**
   * Getter for application config.
   * @public
   */


  var getConfig = function getConfig(option) {
    return config[option] ? config[option] : false;
  };
  /**
   * Expose public methods & properties.
   */


  return {
    init: init,
    config: getConfig
  };
}();
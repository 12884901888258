"use strict";

// Namespacing
window.site = window.site || {}; // Side panel - opened / close

site.Datepicker = function Datepicker($) {
  'use strict';
  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false,
      datepickerSelector = '[data-toggle="datepicker"]',
      datepickerPlaceholder = '.input-birthpicker',
      datepickerReal = '.input-birthpicker';
  /**
   * Initializes the class.
   * @public
   */

  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true; // Initialize selects

    initDatepicker();
    var multiDateSelector = document.querySelector('[data-toggle="multiDatepicker"]');
    var endDate = null;
    var startView = 0;
    var format = 'yyyy-mm-dd';
    var date = null;
    var autoHide = true;
    var offset = 3;

    if (multiDateSelector) {
      endDate = multiDateSelector.dataset.endDate == "now" ? new Date() : multiDateSelector.dataset.endDate;
      startView = multiDateSelector.dataset.startView;
      date = multiDateSelector.value ? multiDateSelector.value : null;
    } // Check if we are using shortcodes for date setting


    switch (date) {
      // Years offset
      case 'offset-years':
        date = new Date(new Date().setFullYear(new Date().getFullYear() + parseInt(_this.data('offset'))));
        break;
    }

    $('[data-toggle="multiDatepicker"]').datepicker({
      format: format,
      endDate: endDate,
      startView: startView,
      date: date,
      autoHide: autoHide,
      offset: offset
    }); // $('.datepicker_placeholder').on('focus', function(){
    //   $(this).hide();
    //   $('[data-toggle="multiDatepicker"]').show().focus();
    // });

    return true;
  };
  /**
   * Init all datepickers on page
   * @public
   */


  var initDatepicker = function initDatepicker() {
    $(datepickerSelector).each(function () {
      var _this = $(this);

      var endDate = _this.data('end-date') ? _this.data('end-date') == "now" ? new Date() : _this.data('end-date') : null,
          startView = _this.data('start-view') ? _this.data('start-view') : 0,
          format = 'yyyy-mm-dd',
          date = _this.val() ? _this.val() : null,
          autoHide = true,
          offset = 3;
      console.log(_this.val()); // Check if we are using shortcodes for date setting

      switch (date) {
        // Years offset
        case 'offset-years':
          date = new Date(new Date().setFullYear(new Date().getFullYear() + parseInt(_this.data('offset'))));
          break;
      }

      _this.datepicker({
        format: format,
        endDate: endDate,
        startView: startView,
        date: date,
        autoHide: autoHide,
        offset: offset,
        hide: updateDateField
      });
    });
    $(datepickerPlaceholder).on('focus', function () {
      $(this).hide();
      $(datepickerReal).show().focus();
    });
    updateDateField();
    return true;
  };
  /**
   * Update date
   */


  var updateDateField = function updateDateField() {
    var el = $(datepickerReal);
    if (el.length == 0) return true;
    el.hide();
    $(datepickerPlaceholder).show();
    setTimeout(function () {
      if (el.val()) {
        var date = el.datepicker('getDate');
        date.setDate(date.getDate() + 1);
        var newDate = el.datepicker('getDate');
        $(datepickerPlaceholder).val(el.datepicker('formatDate', date)).show();
        $('.birthday-text').text(el.datepicker('formatDate', newDate)); // console.log(el.datepicker('formatDate', date + 1));
      } else {
        $(datepickerPlaceholder).val('').show();
      }
    }, 20); // Datepicker sometimes has a small lag between clicking date and updating field - which causes picking up previously selected date
  };
  /**
   * Format day number
   */


  var getGetOrdinal = function getGetOrdinal(n) {
    var s = ["th", "st", "nd", "rd"],
        v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  }; // Expose public methods & properties


  return {
    init: init
  };
}.bind(site, jQuery)();
"use strict";

// Namespacing
window.site = window.site || {}; // Side panel - opened / close

site.SidePanelCart = function SidePanelCart($) {
  'use strict';
  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false,
      $sidePanelOverlay = $('.sidepanel-overlay'),
      $sidePanel = $('.popp002.sidepanel');
  /**
   * Initializes the class.
   * @public
   */

  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true; // Initiate SidePanel

    initEvents();
    $('body').on('click', '[data-close-panel]', function (event) {
      event.preventDefault();
      $sidePanelOverlay.trigger('click');
    });
    return true;
  }; // ------------------------------------
  // INIT
  // ------------------------------------


  var initEvents = function initEvents() {
    $('[data-trigger-sidepanel]').on('click', function (event) {
      event.preventDefault();
      openPanel();
    });
    $('body').on('click', '[data-close-sidepanel]', function (event) {
      event.preventDefault();
      closePanel();
    });
    $('body').on('click', '.side-panel-overlay', function (event) {
      event.preventDefault();
      closePanel();
    });
  };

  var openPanel = function openPanel(loading) {
    var loading = loading || false,
        delay = 0;

    if (loading) {
      delay = 875;
      $sidePanelOverlay.addClass('is-loading');
    }

    $sidePanelOverlay.addClass('opened');
    setTimeout(function () {
      $sidePanel.addClass('opened'); // $('body').css({
      //   "overflow": "hidden",
      //   "position": "absolute",
      //   "width": "100%"
      // });

      $sidePanelOverlay.removeClass('is-loading');
    }, delay);
  };

  var closePanel = function closePanel() {
    $sidePanelOverlay.removeClass('opened');
    $sidePanel.removeClass('opened'); // $( 'body' ).attr('style','');
    // var $ticketsDatePicker = $('#ticketsDatePicker');
    // var $picker = $ticketsDatePicker.pickadate('picker');
    // $picker.trigger('set');
  };

  return {
    init: init,
    openPanel: openPanel,
    closePanel: closePanel
  };
}.bind(site, jQuery)();
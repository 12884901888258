"use strict";

// Namespacing
window.site = window.site || {}; // Component

site.ForfaitsAlert = function ForfaitsAlert($) {
  'use strict';
  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;
  var $forfaitsAlerts = [].slice.call(document.querySelectorAll("[action-has-alert]"));
  /**
   * Initializes the class.
   * @public
   */

  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;
    $forfaitsAlerts.forEach(function ($forfaitBtn) {
      $forfaitBtn.addEventListener('click', function (e) {
        e.preventDefault();
        var alertContent = [$forfaitBtn.dataset.popupTitle, $forfaitBtn.dataset.popupContent, $forfaitBtn.dataset.popupRedirection, $forfaitBtn.dataset.popupConfirm];
        swal({
          title: alertContent[0],
          text: alertContent[1],
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Oui",
          cancelButtonText: "Non",
          closeOnConfirm: true,
          html: false
        }, function (accepts) {
          if (accepts) {
            window.location.href = alertContent[3];
          } else {
            if (alertContent[2] !== '') {
              window.location.href = alertContent[2];
            }
          }
        });
      });
    });
    return true;
  }; // Expose public methods & properties


  return {
    init: init
  };
}.bind(site, jQuery)();
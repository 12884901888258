"use strict";

window.site = window.site || {};
/**
 * Utilities for content.
 * @class Content
 * @static
 */

site.Content = function Content($) {
  'use strict';
  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;
  /**
   * jQuery elements collections
   * @private
   */

  var $share = $('.content-social-media');
  var $accordions = $('.block-accordion');
  var $pageContentAnchor = document.querySelector('#pageContentAnchor');
  var $fieldsets = $('.address-fieldset');
  /**
   * Initializes the class.
   * @public
   */

  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true; // Scroll to direct after the hero

    /*if ($pageContentAnchor) {
      const topPos = $pageContentAnchor.getBoundingClientRect().top + window.scrollY;
       window.scroll({
        top: topPos,
        behavior: "smooth"
      });
    }*/
    // Get all links

    var links = document.querySelectorAll('a'); // Loop through links

    links.forEach(function (link) {
      // Get the href attribute of the link
      var href = link.getAttribute('href'); // Replace 'https://boutique.skisaintbruno.ca' with empty string

      var newHref = href.replace('https://boutique.skisaintbruno.ca', ''); // Set the href attribute of the link to the new href

      link.setAttribute('href', newHref);
    });

    if ($fieldsets.length) {
      $fieldsets.each(function () {
        var $addressFieldset = $(this);
        $('.address-select', $addressFieldset).each(function () {
          this.$element = $(this);
          this.$radio = $('.radio input', this.$element);
          this.$body = $('.body', this.$element);
          this.$radio.change($.proxy(function () {
            $('.body', $addressFieldset).addClass('hidden');
            this.$body.removeClass('hidden');
          }, this));

          if (this.$radio.is(':checked')) {
            this.$body.removeClass('hidden');
          }
        });
      });
    }

    var sameAddressInput = document.querySelector('#sameAddress');
    var sameAsBillingAddressInput = document.querySelector('input[name="shippingAddressSameAsBilling"]');

    if (sameAddressInput) {
      if (sameAddressInput.checked) {
        $('.ShippingAddress').addClass('hidden');
        sameAsBillingAddressInput.value = 1;
      } else {
        $('.ShippingAddress').removeClass('hidden');
        sameAsBillingAddressInput.value = 0;
      }

      sameAddressInput.addEventListener('change', function () {
        if (sameAddressInput.checked) {
          $('.ShippingAddress').addClass('hidden');
          sameAsBillingAddressInput.value = 1;
        } else {
          $('.ShippingAddress').removeClass('hidden');
          sameAsBillingAddressInput.value = 0;
        }
      });
    }

    $('.ShippingAddress [required]').addClass('optional-required').removeAttr('required');
    checkOptionalRequired();
    $('#shippingMethod2').on('click change focus blur', function (event) {
      if ($('#shippingMethod2').is(':checked')) {
        $('[type="file"]').prop('required', true);
        $('.dynamic-zone.zone-shipping-only').show();
      } else {
        $('[type="file"]').prop('required', false);
        $('.dynamic-zone.zone-shipping-only').hide();
      }
    });
    $('#shippingMethod').on('click change focus blur', function (event) {
      console.log($('#shippingMethod2').is(':checked'));

      if ($('#shippingMethod2').is(':checked')) {
        $('[type="file"]').prop('required', true);
        $('.dynamic-zone.zone-shipping-only').show();
      } else {
        $('[type="file"]').prop('required', false);
        $('.dynamic-zone.zone-shipping-only').hide();
      }
    });

    if ($('#shippingMethod2').is(':checked')) {
      $('[type="file"]').prop('required', true);
      $('.dynamic-zone.zone-shipping-only').show();
    } else {
      $('[type="file"]').prop('required', false);
      $('.dynamic-zone.zone-shipping-only').hide();
    } // Initialize content blocks


    initAccordions(); // Initialize share buttons

    $('.social-facebook', $share).on('click', function (e) {
      e.preventDefault();
      shareFacebook();
    });
    $('.social-twitter', $share).on('click', function (e) {
      e.preventDefault();
      shareTwitter();
    });
    $('.social-linkedin', $share).on('click', function (e) {
      e.preventDefault();
      shareLinkedIn();
    }); // Refactor Prices trailing cents format

    $.each($('[data-format-price]'), function (index, val) {
      var delimiter = ',';
      console.log("LANG === ", window.site.App.config('locale'));

      if (window.site.App.config('locale') === 'en') {
        delimiter = '.';
      }

      var $price = $(this);
      var actualValue = $price.text();
      var parts = actualValue.split(delimiter);
      $price.html(parts[0] + ' <i class="dollar-sign">' + parts[1] + '</i>');

      if (window.site.App.config('locale') === 'en') {} else {}
    }); // Return success

    return true;
  };

  var globalCheck = function globalCheck() {
    $('.checkbox-label .checkbox').each(function () {
      if (this.checked) {
        $(this).parent().addClass("is-checked");
      } else {
        $(this).parent().removeClass("is-checked");
      }
    });
  };

  var checkOptionalRequired = function checkOptionalRequired() {
    if ($('.ShippingAddress').hasClass('hidden')) {
      $('.ShippingAddress .optional-required').removeAttr('required');
    } else {
      $('.ShippingAddress .optional-required').attr('required', 'required');
    }
  };
  /**
   * Initialize accordions blocks.
   * @private
   */


  var initAccordions = function initAccordions() {
    $accordions.each(function () {
      var $accordion = $(this);
      $('.block-accordion-content', $accordion).on('click', function (e) {
        e.stopPropagation();
      });
      $('body').on('click', '.block-accordion-row', function (e) {
        var $row = $(this);

        if (!$row.hasClass('is-active')) {
          closeAccordionRows($row.parents('.block-accordion'));
          $row.addClass('is-active');
          $('.block-accordion-content', $row).stop().slideDown(275, function () {
            $('html, body').animate({
              scrollTop: $row.offset().top - 120
            }, 200);
          });
        } else {
          closeAccordionRows($row.parents('.block-accordion'));
        }
      }); // Default open the first row

      $('.block-accordion-row', $accordion).eq(0).trigger('click');
    }); // On Form Validation, we need to double check there is no error in a closed accordion.

    $('body').on('click', '.site-btn, [type="submit"]', function (event) {
      setTimeout(function () {
        $('.block-accordion-row', $accordions).each(function () {
          $(this).removeClass('has-error');
          $('.error-label', $(this)).hide();
        });
        $('.parsley-error', $accordions).each(function () {
          var $erroredInput = $(this);
          var $accordionRow = $erroredInput.parents('.block-accordion-row');
          $accordionRow.addClass('has-error');
          $('.error-label', $accordionRow).show();
        });

        if (!$('.block-accordion-row.has-error').hasClass('is-active')) {
          $('.block-accordion-row.has-error').eq(0).trigger('click');
        }
      }, 555);
    });
  };
  /**
   * Close all opened rows for an accordion.
   * @private
   */


  var closeAccordionRows = function closeAccordionRows($accordion) {
    var $rows = $('.block-accordion-row', $accordion);
    $rows.each(function () {
      var $row = $(this);
      $('.block-accordion-content', $row).stop().slideUp(275, function () {
        $row.removeClass('is-active');
      });
    });
  };
  /**
   * Share page via Facebook.
   * @private
   */


  var shareFacebook = function shareFacebook() {
    FB.ui({
      method: 'share',
      display: 'popup',
      href: location.href
    });
  };
  /**
   * Share page via Twitter.
   * @private
   */


  var shareTwitter = function shareTwitter() {
    var width = 575;
    var height = 250;
    var left = ($(window).width() - width) / 2 + window.screenX;
    var top = ($(window).height() - height) / 2;
    var opts = 'status=1,width=' + width + ',height=' + height + ',top=' + top + ',left=' + left;
    var url = 'https://twitter.com/intent/tweet?lang=fr';
    url += '&text=' + encodeURIComponent($('meta[name="twitter:title"]').attr('content'));
    url += '&url=' + location.href;
    window.open(url, 'twitter', opts);
  };
  /**
   * Share via LinkedIn.
   * @private
   */


  var shareLinkedIn = function shareLinkedIn() {
    var width = 575;
    var height = 431;
    var left = ($(window).width() - width) / 2 + window.screenX;
    var top = ($(window).height() - height) / 2;
    var opts = 'status=1,width=' + width + ',height=' + height + ',top=' + top + ',left=' + left;
    var url = 'https://www.linkedin.com/shareArticle?mini=true';
    url += '&summary=' + encodeURIComponent($('meta[name="twitter:title"]').attr('content'));
    url += '&url=' + location.href;
    url += '&source=OIIAQ';
    window.open(url, 'linkedin', opts);
  }; // Expose public methods & properties


  return {
    init: init,
    initAccordions: initAccordions,
    checkOptionalRequired: checkOptionalRequired,
    globalCheck: globalCheck
  };
}(jQuery);
"use strict";

// Namespacing
window.site = window.site || {}; // Component

site.GoogleCaptcha = function GoogleCaptcha($) {
  'use strict';
  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;
  /**
   * Initializes the class.
   * @public
   */

  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    var $submitBtn = $('[action-google-verif-btn]');
    $submitBtn.on('click', function (e) {
      var $form = $(this.dataset.linkedForm);
      var $tokenHolder = $(this.dataset.linkedResponse);
      var $errorField = $(this.dataset.errorField);
      var isValidUser = false;
      var googleActionValue = this.dataset.actionValue;
      var currentLocale = this.dataset.locale;
      grecaptcha.ready(function () {
        grecaptcha.execute('6LcgTjUeAAAAAAY6xLg4HSgX2_rcKRn3ZWbouR1N', {
          action: googleActionValue
        }).then(function (token) {
          $tokenHolder.value = token;
          $.ajax({
            type: 'get',
            url: '/site/getGoogleValidation?token=' + $tokenHolder.value + '&locale=' + currentLocale,
            dataType: 'json',
            async: false,
            success: function success(res) {
              console.log(res);

              if (res.error) {
                $errorField.text(res.message);
              } else {
                isValidUser = true;
              }
            }
          });

          if (isValidUser) {
            $form.submit();
          }
        });
      });
    });
    inited = true;
    return true;
  }; // Expose public methods & properties


  return {
    init: init
  };
}.bind(site, jQuery)();
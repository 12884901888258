"use strict";

// Namespacing
window.site = window.site || {}; // Component

site.ZipcodeMask = function ZipcodeMask($) {
  'use strict';
  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;
  /**
   * Initializes the class.
   * @public
   */

  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    var zipcodeInput = $('[data-zipcode-mask]');

    if (zipcodeInput) {
      Inputmask({
        regex: "^[A-Za-z]\\d[A-Za-z] \\d[A-Za-z]\\d$"
      }).mask(zipcodeInput);
      $(document).on('keypress', function (evt) {
        if (evt.isDefaultPrevented()) {
          console.log('log'); // Assume that's because of maskedInput
          // See https://github.com/guillaumepotier/Parsley.js/issues/1076

          $(evt.target).trigger('input');
        }
      });
    }

    inited = true;
    return true;
  }; // Expose public methods & properties


  return {
    init: init
  };
}.bind(site, jQuery)();
"use strict";

window.site = window.site || {};
/**
 * Preview uploaded image on user dashboart without uploading
 * @class UploadedImagePreview
 * @static
 */

site.ForfaitsSelection = function ForfaitsSelection($) {
  'use strict';
  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;
  var $forfaitsBtn = [].slice.call(document.querySelectorAll('.js-cat-toggle'));
  var $forfaitsText = [].slice.call(document.querySelectorAll('.categories-text'));
  var $forfaitsGrids = [].slice.call(document.querySelectorAll('.forfaits-grid'));
  /**
   * Initializes the class.
   * @public
   */

  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;
    $forfaitsBtn.forEach(function ($forfaitBtn) {
      $forfaitBtn.addEventListener('click', function (e) {
        var currentCat = $forfaitBtn.dataset.forfaitType;
        stripClass($forfaitsBtn, 'is-active');
        stripClass($forfaitsText, 'is-active');
        stripClass($forfaitsGrids, 'is-active');
        $forfaitBtn.classList.add('is-active');
        document.querySelector('.categories-text[data-forfait-type="' + currentCat + '"]').classList.add('is-active');
        document.querySelector('.forfaits-grid[data-forfait-type="' + currentCat + '"]').classList.add('is-active');
      });
    });
    return true;
  };

  var stripClass = function stripClass(targetElements, targetClass) {
    targetElements.forEach(function (targetElement) {
      targetElement.classList.remove(targetClass);
    });
  }; // Expose public methods & properties


  return {
    init: init
  };
}(jQuery);
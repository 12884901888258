window.Parsley.addValidator('minAge', {
  validateString: function(_value, age, parsleyInstance) {
    console.log("minAge", _value, age, parsleyInstance);

    var parts = _value.split('-');
    // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
    // January - 0, February - 1, etc.
    var mydate = new Date(parts[0], parts[1] - 1, parts[2]);

    console.log("date=" + mydate.toDateString());

    var ageDifMs = Date.now() - mydate.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    var ageCalc = Math.abs(ageDate.getUTCFullYear() - 1970);

    console.log("ageCalc=" + ageCalc );

    if ( ageCalc >= 60) {
      return true;
    }

    return false;
  },
  requirementType: 'integer',
  messages: {
    en: 'You must be at least 60 year old',
    fr: 'Vous devez avoir au minimum 60 ans'
  }
});

window.Parsley.addValidator('filemaxmegabytes', {
  requirementType: 'string',
  validateString: function (value, requirement, parsleyInstance) {

      // if (!app.utils.formDataSuppoerted) {
      //     return true;
      // }

      var file = parsleyInstance.$element[0].files;
      var maxBytes = requirement * 1048576;

      if (file.length == 0) {
          return true;
      }

      return file.length === 1 && file[0].size <= maxBytes;

  },
  messages: {
      en: 'File is to big',
      fr: 'Le fichier est trop gros'
  }
}).addValidator('filemimetypes', {
  requirementType: 'string',
  validateString: function (value, requirement, parsleyInstance) {

      // if (!app.utils.formDataSuppoerted) {
      //     return true;
      // }

      var file = parsleyInstance.$element[0].files;

      if (file.length == 0) {
          return true;
      }

      var allowedMimeTypes = requirement.replace(/\s/g, "").split(',');
      return allowedMimeTypes.indexOf(file[0].type) !== -1;

  },
  messages: {
      en: 'Only .jpg format is allowed',
      fr: 'Nous acceptions seulement le format .jpg des images'
  }
});
